@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: 0;
}

body {
    font: 400 14px Roboto, sans-serif;
    background: #212121;
    -webkit-font-smoothing: antialiased;
}